﻿/* DBS AMENDS */

.navbar-default .navbar-toggle {
    border: 0;
    padding: 0;
    background-color: transparent !important;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: @brand-primary;
    width: 54px;
    height: 3px;
}

.navbar-default .navbar-toggle .icon-bar + .icon-bar {
    margin-top: 12px;
}

@media(max-device-width: @grid-float-breakpoint) {
.navbar-default .navbar-collapse {
    position: absolute;
    top: 100%;
    right: @navbar-padding-horizontal;
    z-index: @zindex-dropdown;
    background-color: @brand-primary;
    color: #fff;
}

.navbar-default .navbar-nav {
    margin-left: 0;
    margin-right: 0;

    > li > a {
        padding: 10px 25px;
    }
}

#page-navigation .navbar-nav > li > a {
    color: #fff !important;
}
}